const CircleBlur = () => {
    return (
        <div className="canvas">
            <div className="circles">
                <div className="circle research"></div>
                <div className="circle design"></div>
            </div>
            <div className="overlay"></div>
        </div>
    );
};

export default CircleBlur;
