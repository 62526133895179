import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/loadable';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/materialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/tablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

//main menu
const BillingReportDetail = Loadable(lazy(() => import('views/billing/layouts/report/detail')));
const Helpdesk = Loadable(lazy(() => import('views/helpdesk/layouts')));
const Overview = Loadable(lazy(() => import('views/overview/layouts')));
const VirtualMachines = Loadable(lazy(() => import('views/virtualMachines/layouts')));
const VirtualMachinesDetail = Loadable(lazy(() => import('views/virtualMachines/layouts/detail')));
const CreateVm = Loadable(lazy(() => import('views/virtualMachines/layouts/createVm')));
const CreateTicket = Loadable(lazy(() => import('views/helpdesk/layouts/components/pages/createTicket')));
const ChatHelpdesk = Loadable(lazy(() => import('views/helpdesk/layouts/main/chatHelpdesk')));
const ChatCWHelpdesk = Loadable(lazy(() => import('views/helpdesk/layouts/chatwoot/chatCWHelpdesk')));
const UpdateTicket = Loadable(lazy(() => import('views/helpdesk/layouts/main/updateHelpdesk')));
const Profile = Loadable(lazy(() => import('views/profile/layouts/index')));
const Notification = Loadable(lazy(() => import('views/notification/layouts')));
const PrivateNetwork = Loadable(lazy(() => import('views/network/layouts/privateNetwork')));
const PublicNetwork = Loadable(lazy(() => import('views/network/layouts/publicNetwork')));
const SSH = Loadable(lazy(() => import('views/authentication/layouts/ssh')));
const APIToken = Loadable(lazy(() => import('views/authentication/layouts/apiToken')));
const BlockStorage = Loadable(lazy(() => import('views/storage/layouts/blockStorage')));
const TopUp = Loadable(lazy(() => import('views/billing/layouts/payment')));
const TopUpHistory = Loadable(lazy(() => import('views/billing/layouts/topUpInvoice')));
const CreditHistory = Loadable(lazy(() => import('views/billing/layouts/creditHistory')));
const Report = Loadable(lazy(() => import('views/billing/layouts/report')));
const PostpaidInvoice = Loadable(lazy(() => import('views/billing/layouts/postpaidInvoice/postpaidInvoice')));
const PostpaidBillPayment = Loadable(lazy(() => import('views/billing/layouts/payment')));
const Announcement = Loadable(lazy(() => import('views/info/layouts/announcement')));
const ReleaseNotes = Loadable(lazy(() => import('views/info/layouts/releaseNotes')));
const Apps = Loadable(lazy(() => import('views/appCategory/layouts')));

// ==============================|| MAIN ROUTING ||============================== //

const withChatwoot = process.env.REACT_APP_USE_CW == 'true';

const requireAuth = (Component, location) => {
    const AuthComponent = () => {
        const isSuspended = useSelector((state) => state.authReducer.user_info?.userinfo?.is_suspended);
        const navigate = useNavigate();

        if (!isSuspended) {
            return <Component />;
        } else {
            navigate(location);
            return null;
        }
    };

    AuthComponent.displayName = `requireAuth(${Component.displayName || Component.name || 'Component'})`;

    return AuthComponent;
};

// Wrap the CreateVm component with the requireAuth HOC
const CreateVmWithSuspended = requireAuth(CreateVm, '/instances');

// Wrap the VirtualMachinesDetail component with the requireAuth HOC
// const DetailVmWithSuspended = requireAuth(VirtualMachinesDetail, '/instances');

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Overview />,
            exact: true
        },
        {
            path: '/dashboard',
            element: <Overview />,
            exact: true
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },

        {
            path: 'instances',
            element: <VirtualMachines />,
            exact: true
        },
        {
            path: 'instances/:name',
            element: <VirtualMachinesDetail />,
            exact: true
        },
        {
            path: 'instances/create-vm',
            element: <CreateVmWithSuspended />,
            exact: true
        },
        {
            path: 'helpdesk',
            element: <Helpdesk />,
            exact: true
        },
        {
            path: 'helpdesk/create-ticket',
            element: <CreateTicket />,
            exact: true
        },
        {
            path: 'helpdesk/chat/:token',
            element: withChatwoot ? <ChatCWHelpdesk /> : <ChatHelpdesk />,
            exact: true
        },
        {
            path: 'helpdesk/update-ticket/:token',
            element: <UpdateTicket />,
            exact: true
        },
        {
            path: 'billing/detail/:date',
            element: <BillingReportDetail />,
            exact: true
        },
        {
            path: 'storage/block_storage',
            element: <BlockStorage />,
            exact: true
        },
        {
            path: 'profile',
            element: <Profile />,
            exact: true
        },
        {
            path: 'notification/view-all',
            element: <Notification />,
            exact: true
        },
        {
            path: 'network/private_network',
            element: <PrivateNetwork />,
            exact: true
        },
        {
            path: 'network/public_network',
            element: <PublicNetwork />,
            exact: true
        },
        {
            path: 'authentication/ssh',
            element: <SSH />,
            exact: true
        },
        {
            path: 'authentication/api_token',
            element: <APIToken />,
            exact: true
        },
        {
            path: 'billing/top-up',
            element: <TopUp />,
            exact: true
        },
        {
            path: 'billing/top_up_history',
            element: <TopUpHistory />,
            exact: true
        },
        {
            path: 'billing/credit_history',
            element: <CreditHistory />,
            exact: true
        },
        {
            path: 'billing/report',
            element: <Report />,
            exact: true
        },
        {
            path: 'billing/invoice',
            element: <PostpaidInvoice />,
            exact: true
        },
        {
            path: 'billing/invoice/bill-payment',
            element: <PostpaidBillPayment />,
            exact: true
        },
        {
            path: '/announcement',
            element: <Announcement />,
            exact: true
        },
        {
            path: '/release_notes',
            element: <ReleaseNotes />,
            exact: true
        },
        {
            path: '/apps',
            element: <Apps />,
            exact: true
        }
    ]
};

export default MainRoutes;
