import api from './api';

const getAllNotifications = (type, limit, page) => {
    return api.get(`/notifications/all?type=${type}&page=${page}&limit=${limit}`);
};

const readNotification = (uuid) => {
    return api.patch(`/notifications/${uuid}/read`);
};

const readAllNotification = () => {
    return api.patch(`/notifications/read`);
};

const NotificationService = {
    getAllNotifications,
    readNotification,
    readAllNotification
};

export default NotificationService;
