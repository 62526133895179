// project imports

import { ACCESS, ANNOUNCEMENT, CREDS_FETCH, ELIGIBLE, EMAIL_VERIFY, OPEN_MODAL_REWARD, REF, USER_INFO } from 'store/actions/authAction';

// action - state management

export const initialState = {
    email_verify: '',
    user_info: {},
    access: false,
    eligible: false,
    ref_el: false,
    open_modal_reward: { flag: '', open: false },
    credsFetch: true,
    announcement: []
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMAIL_VERIFY:
            return {
                ...state,
                email_verify: action.payload
            };
        case USER_INFO:
            return {
                ...state,
                user_info: action.payload
            };
        case ACCESS:
            return {
                ...state,
                access: action.payload
            };
        case ELIGIBLE:
            return {
                ...state,
                eligible: action.payload
            };
        case REF:
            return {
                ...state,
                ref_el: action.payload
            };
        case OPEN_MODAL_REWARD:
            return {
                ...state,
                open_modal_reward: action.payload
            };
        case CREDS_FETCH:
            return {
                ...state,
                credsFetch: action.payload
            };
        case ANNOUNCEMENT:
            return {
                ...state,
                announcement: action.payload
            };

        default:
            return state;
    }
};

export default authReducer;
