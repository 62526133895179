export const DATA_VSWITCH = 'DATA_VSWITCH';
export const UUID = 'UUID';
export const EDGE = 'EDGE';
export const NODES = 'NODES';
export const LOADING_FLOW = 'LOADING_FLOW';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const OPEN_GRAPH = 'OPEN_GRAPH';
export const OPEN_DRAWER_NETWORK = 'OPEN_DRAWER_NETWORK';
export const DATA_LOCATION = 'DATA_LOCATION';
export const RESERVED_IP = 'RESERVED_IP';
export const TAB_NETWORK = 'TAB_NETWORK';
export const DATA_VIRTUAL_SWITCH_DETAIL = 'DATA_VIRTUAL_SWITCH_DETAIL';
export const DATA_PRIVATE_VIRTUAL_SWITCH = 'DATA_PRIVATE_VIRTUAL_SWITCH';
export const OPTIONS_VM_GATEWAY = 'OPTIONS_VM_GATEWAY';
export const GATEWAY = 'GATEWAY';
export const DATA_VM = 'DATA_VM';
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const INSTANCE_SELECTED = 'INSTANCE_SELECTED';
