import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconReward from '../../../../assets/images/icons/box_reward.svg';

import MainModal from 'ui-component/modal/mainModal';

import { useDispatch, useSelector } from 'react-redux';

import FormVerificationIdentity from 'ui-component/form/formVerificationIdentity';

const RewardBoxIcon = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.authReducer.open_modal_reward);
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const billingSystem = JSON.parse(localStorage.getItem('user_info'))?.userinfo?.billing;

    const handleClaim = () => {
        dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'header', open: true } });
    };

    return (
        <>
            <Box
                onClick={handleClaim}
                display={billingSystem === 'PRE' ? 'flex' : 'none'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    border: '1px solid #eeee',
                    padding: '7px',
                    borderRadius: '8px',
                    marginRight: matchesXs ? '0px' : '18px',
                    cursor: 'pointer',
                    '&:hover': {
                        background: '#EEF3FC'
                    }
                }}
            >
                <img src={IconReward} alt="" />
            </Box>

            {open.flag === 'header' ? (
                <MainModal
                    openModal={open.open}
                    handleCloseModal={() => dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'header', open: false } })}
                    content={
                        <Box mt={2} mb={2}>
                            <FormVerificationIdentity />
                        </Box>
                    }
                />
            ) : null}
        </>
    );
};

export default RewardBoxIcon;
