export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const PAYMENT_METHOD_POST = 'PAYMENT_METHOD_POST';
export const AMOUNT = 'AMOUNT';
export const CODE = 'CODE';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const METHOD = 'METHOD';
export const DATA_INVOICE = 'DATA_INVOICE';
export const PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED';
export const MESSAGE_CREATE_INVOICE = 'MESSAGE_CREATE_INVOICE';
export const STATUS_TOP_UP = 'STATUS_TOP_UP';
export const TAB = 'TAB';
export const REFRESH_INVOICE = 'REFRESH_INVOICE';
export const STEPPER_ACTIVE = 'STEPPER_ACTIVE';
