const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem('user_info'));
    return user?.refreshToken;
};

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user_info'));
    return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem('user_info'));
    user.accessToken = token;
    localStorage.setItem('user_info', JSON.stringify(user));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem('user_info'));
};

const setUser = (user) => {
    if (user.data.is_registration_completed) {
        localStorage.setItem('user_info', JSON.stringify(user));
    } else {
        return null;
    }
};

const removeUser = () => {
    localStorage.removeItem('user_info');
    localStorage.removeItem('next_fetch');
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser
};

export default TokenService;
