import { Box, Button, Typography } from '@mui/material';
import notFound from '../../assets/images/not-found.svg';

const NotFound = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box>
                <Box>
                    <img src={notFound} alt="" />

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <Typography align="center" mt={3} color="#1D243C" fontWeight="bold" fontSize="40px">
                                404 Error: Page not found.
                            </Typography>
                            <Typography mt={2} style={{ maxWidth: '500px' }} align="center" color="#76777F">
                                Oops! It seems like the page you were trying to access doesn&apos;t exist. Please navigate back to the
                                homepage.
                            </Typography>
                            <Box sx={{ textAlign: 'center' }} mt={3}>
                                <Button onClick={() => (window.location.href = '/')} variant="contained" color="primary">
                                    Back to home
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFound;
