import api from './api';
import chatwootApi from './chatwoot';

const createTicket = (data) => {
    return api.post('/ticket/create', data);
};

const getMyTicket = (limit, page, status, department, priority, sort) => {
    return api.get(
        `/ticket/tickets?statuses=${status}&departments=${department}&priority=${priority}&limit=${limit}&page=${page}&sort=${sort}`
    );
};

const getTicketDetail = (ticket_token) => {
    return api.get(`/ticket/${ticket_token}/detail`);
};

const updateTicket = (ticket_token) => {
    return api.patch(`/ticket/${ticket_token}/update`);
};

const replyTicket = (ticket_token, formData) => {
    return api.post(`/ticket/${ticket_token}/reply`, formData);
};

const getTicketConversations = (ticket_token, page) => {
    return api.get(`/ticket/${ticket_token}/conversations?page=${page}`);
};

const getMyCwTicket = (limit, page, status, department, priority, sort) => {
    return api.get(
        `/ticket/cw/tickets?statuses=${status}&departments=${department}&priority=${priority}&limit=${limit}&page=${page}&sort=${sort}`
    );
};

const createCwTicket = (data) => {
    return api.post('/ticket/cw/create', data);
};

const getCwTicketDetail = (ticket_number) => {
    return api.get(`/ticket/cw/${ticket_number}/details`);
};

const replyCwTicket = (inbox_id, contact_id, conversation_id, messageData) => {
    return chatwootApi.post(
        `/public/api/v1/inboxes/${inbox_id}/contacts/${contact_id}/conversations/${conversation_id}/messages`,
        messageData,
        {
            headers: { 'Content-Type': 'multipart/form-data' }
        }
    );
};

const getUnreadTicketNotification = () => {
    return api.get(`/ticket/cw/notifications`);
};

const markTicketasRead = (id) => {
    return api.patch(`/ticket/cw/${id}/read`);
};

const TicketService = {
    createTicket,
    getMyTicket,
    getTicketDetail,
    updateTicket,
    replyTicket,
    getTicketConversations,
    getMyCwTicket,
    createCwTicket,
    getCwTicketDetail,
    replyCwTicket,
    getUnreadTicketNotification,
    markTicketasRead
};

export default TicketService;
