import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('user_info')) {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Outlet />
        </>
    );
};

export default MinimalLayout;
