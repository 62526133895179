import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './reducers/authReducer';
import billingReducer from './reducers/billingReducer';
import vmReducer from './reducers/vmReducers';
import notificationReducer from './reducers/notificationReducer';
import profileReducer from './reducers/profileReducer';
import networkReducer from './reducers/networkReducer';
import storageReducer from './reducers/storageReducer';
import authenticationReducer from './reducers/authenticationReducer';
import alertReducer from './reducers/alertReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authReducer: authReducer,
    billingReducer: billingReducer,
    vmReducer: vmReducer,
    notificationReducer: notificationReducer,
    profileReducer: profileReducer,
    networkReducer: networkReducer,
    storageReducer: storageReducer,
    authenticationReducer: authenticationReducer,
    alertReducer: alertReducer
});

export default reducer;
