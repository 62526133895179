export const TAB_MENU_STORAGE = 'TAB_MENU_STORAGE';
export const DRAWER_STORAGE = 'DRAWER_STORAGE';
export const INSTANCE_VALUE_STORAGE = 'INSTANCE_VALUE_STORAGE';
export const LOCATION_VALUE_STORAGE = 'LOCATION_VALUE_STORAGE';
export const FORM_BLOCK_STORAGE = 'FORM_BLOCK_STORAGE';
export const DATA_LOCATION_BS = 'DATA_LOCATION_BS';
export const DATA_VM_BS = 'DATA_VM_BS';
export const INSTANCE_SELECTED_BS = 'INSTANCE_SELECTED_BS';
export const DETAIL_BLOCK_STORAGE = 'DETAIL_BLOCK_STORAGE';
export const RESIZE_STORAGE = 'RESIZE_STORAGE';
export const AVAILABLE_BS = 'AVAILABLE_BS';
