import api from './api';

const getLatestVirtualMachineDetails = () => {
    return api.get('/dashboard/instances');
};

const getTotalResources = () => {
    return api.get(`/dashboard/resources`);
};

const getBillingInsight = () => {
    return api.get(`/dashboard/billing`);
};

//Referral
const getReferral = () => {
    return api.get(`/dashboard/referral`);
};

const updateReferralCode = (referral_code) => {
    return api.patch(`/dashboard/referral/update`, { referral_code: referral_code });
};

//Redeem Code
const redeemCode = (code) => {
    return api.patch(`/gift/reedem`, {
        code: code
    });
};

//verification
const identityVerifcation = (data) => {
    return api.post(`/dashboard/identity-verification`, data);
};

const DashboardService = {
    getLatestVirtualMachineDetails,
    getTotalResources,
    getBillingInsight,
    getReferral,
    updateReferralCode,
    redeemCode,
    identityVerifcation
};

export default DashboardService;
