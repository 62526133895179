import { Avatar, Box, ButtonBase, ClickAwayListener, Paper, Popper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { dynamicBoxShadow } from 'helpers/styling';
import MainCard from 'ui-component/cards/mainCard';
import Transitions from 'ui-component/extended/transitions';
import PropTypes from 'prop-types';

export const ButtonNotification = ({ anchorRef, open, handleToggle, children }) => {
    const theme = useTheme();
    return (
        <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: 'none',
                    boxShadow: dynamicBoxShadow,
                    borderRadius: '10px',
                    color: theme.palette.primary.main,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.primary.light,
                        color: theme.palette.primary.light
                    }
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
            >
                {children}
            </Avatar>
        </ButtonBase>
    );
};

ButtonNotification.propTypes = {
    anchorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
    open: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};

export const PopupNotification = ({ children, open, anchorEl, handleClose }) => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Popper
            placement={matchesXs ? 'bottom' : 'bottom-end'}
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]
            }}
        >
            {({ TransitionProps }) => (
                <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MainCard
                                sx={{ background: 'white !important' }}
                                border={false}
                                elevation={16}
                                content={false}
                                boxShadow
                                shadow={theme.shadows[16]}
                            >
                                <Box>{children}</Box>
                            </MainCard>
                        </ClickAwayListener>
                    </Paper>
                </Transitions>
            )}
        </Popper>
    );
};

PopupNotification.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
    handleClose: PropTypes.func.isRequired
};
