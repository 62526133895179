import { lazy } from 'react';
import FreeLayout from 'layout/FreeLayout';

// project imports
import Loadable from 'ui-component/loadable';

// login option 3 routing
const Verification = Loadable(lazy(() => import('views/pages/authentication/authentication3/verification')));
const VMConsole = Loadable(lazy(() => import('views/virtualMachines/layouts/console/console')));
const ConsoleMobile = Loadable(lazy(() => import('views/virtualMachines/layouts/console/consoleMobile')));
const AuthImpersonate = Loadable(lazy(() => import('views/pages/authentication/auth-forms/layouts/authImpersonate')));
const ChangePassword = Loadable(lazy(() => import('views/changePassword')));
const VerifyNewEmail = Loadable(lazy(() => import('views/verifyNewEmail')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const VerificationOtp = {
    path: '/',
    element: <FreeLayout />,
    children: [
        {
            path: '/verification/otp',
            element: <Verification />
        },
        {
            path: 'instances/:name/console',
            element: <VMConsole />,
            exact: true
        },
        {
            path: 'mobile/console',
            element: <ConsoleMobile />,
            exact: true
        },
        {
            path: 'impersonate',
            element: <AuthImpersonate />,
            exact: true
        },
        {
            path: 'change-password',
            element: <ChangePassword />,
            exact: true
        },
        {
            path: 'verify-new-email',
            element: <VerifyNewEmail />,
            exact: true
        }
    ]
};

export default VerificationOtp;
