//router
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './mainRoutes';
import AuthenticationRoutes from './authenticationRoutes';

import VerificationOtp from './verificationOtp';
import NotFound from './notFound';

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    const page = [MainRoutes, VerificationOtp, AuthenticationRoutes, NotFound];

    return useRoutes(page);
}
