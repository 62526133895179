export const getLogoPath = () => {
    return require(`../assets/images/logo/${process.env.REACT_APP_NAME}/logo-l.png`);
};

export const getLogoPath2 = () => {
    return require(`../assets/images/logo/${process.env.REACT_APP_NAME}/logo-s.png`);
};

export const getLogoLoading = () => {
    try {
        return require(`../assets/images/logo/${process.env.REACT_APP_NAME}/logo-neutral.png`);
    } catch (error) {
        return null;
    }
};
