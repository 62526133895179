import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, CardActions, Divider, Grid, Stack, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import NotificationList from './notificationList';
import { dynamicBoxShadow } from 'helpers/cardShadow';

// assets
import notificationIcon from '../../../../assets/images/icons/notification.svg';
import { ButtonNotification, PopupNotification } from '../NotificationComponent';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();

    const navigate = useNavigate();

    const notifStore = useSelector((state) => state.notificationReducer.all_notification);
    const totalUnreadStore = useSelector((state) => state.notificationReducer.total_unread);

    const [open, setOpen] = useState(false);

    const [buttonNotif, setButtonNotif] = useState('General');

    const [notif, setNotif] = useState([]);
    const [totalUnread, setTotalUnread] = useState([]);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setNotif(notifStore);
        setTotalUnread(totalUnreadStore);
    }, [notifStore, totalUnreadStore]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonNotification anchorRef={anchorRef} handleToggle={handleToggle} open={open}>
                    {totalUnread.total_unread_general + totalUnread.total_unread_service === 0 || notifStore.length === 0 ? (
                        <img src={notificationIcon} alt="" />
                    ) : (
                        <>
                            <Badge sx={{ position: 'relative', top: '-8px', left: '15px' }} variant="dot" color="error"></Badge>
                            <img src={notificationIcon} alt="" />
                        </>
                    )}
                </ButtonNotification>
            </Box>
            <PopupNotification open={open} anchorEl={anchorRef.current} handleClose={handleClose}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container mb={2} alignItems="center" sx={{ pt: 2, px: 2 }}>
                            <Grid xs={12} display="flex" alignItems={'center'} item>
                                <Box mr={1} sx={{ boxShadow: dynamicBoxShadow, borderRadius: '8px', padding: '7px 8px' }}>
                                    <img src={notificationIcon} alt="" />
                                </Box>
                                <Stack direction="row" spacing={2}>
                                    <Box>
                                        <Typography fontWeight="bold" fontSize="16px" variant="subtitle1">
                                            Notification
                                        </Typography>
                                        <Typography color="#959595" fontSize="14px">
                                            Stay connected with notifications
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 0 }} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ px: 2, mt: 2 }}>
                                    <Stack direction="row">
                                        {['General', 'Service'].map((res, i) => (
                                            <Button
                                                key={res}
                                                onClick={() => setButtonNotif(res)}
                                                sx={{
                                                    boxShadow: 'none',
                                                    borderTopRightRadius: i === 0 ? '0px !important' : '8px',
                                                    borderBottomRightRadius: i === 0 ? '0px !important' : '8px',
                                                    borderTopLeftRadius: i === 1 ? '0px !important' : '8px',
                                                    borderBottomLeftRadius: i === 1 ? '0px !important' : '8px',
                                                    '&:hover': {
                                                        boxShadow: 'none',
                                                        background: theme.palette.primary.light
                                                    }
                                                }}
                                                fullWidth
                                                variant="contained"
                                                color={res === buttonNotif ? 'primary' : 'inherit'}
                                                size="large"
                                            >
                                                {res}
                                            </Button>
                                        ))}
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={0}></Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ paddingTop: '0 !important' }} item xs={12}>
                        <PerfectScrollbar style={{ height: '300px', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                            <NotificationList
                                notifStore={notifStore}
                                listNotif={buttonNotif === 'Service' ? notif.Service : notif.General}
                            />
                        </PerfectScrollbar>
                    </Grid>
                </Grid>
                <Divider />
                <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button onClick={() => navigate('/notification/view-all')} size="small" disableElevation>
                        View All
                    </Button>
                </CardActions>
            </PopupNotification>
        </>
    );
};

export default NotificationSection;
