// project imports

import { ALL_NOTIFICATION, TOTAL_UNREAD, UNREAD_TICKET_NOTIFICATION } from 'store/actions/actionReducer';

// action - state management

export const initialState = {
    all_notification: [],
    total_unread: [],
    all_unread_notifcation: []
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_NOTIFICATION:
            return {
                ...state,
                all_notification: action.payload
            };

        case UNREAD_TICKET_NOTIFICATION:
            return {
                ...state,
                all_unread_notifcation: action.payload
            };
        case TOTAL_UNREAD:
            return {
                ...state,
                total_unread: action.payload
            };

        default:
            return state;
    }
};

export default notificationReducer;
