import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://' + process.env.REACT_APP_CW_BASE_URL,
    headers: {
        Accept: 'application/json'
    }
});

export default instance;
